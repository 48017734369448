module NewsService {
    let app = angular.module("app");

    app.factory("NewsService", ["$resource", "GlobalApplicationData", ($resource: ng.resource.IResourceService, GlobalApplicationData: any) => {
        let serv = {
            // Recupera le news dell'utente
            getAllNews: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/get-all-announcements', {
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            getNewsDetails: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/get-announcement-details/:announcementId', {
                announcementId: "@announcementId",
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),
            
            deleteNews: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/delete-announcement/:announcementId', {
                announcementId: "@announcementId",
            }, {
                    query: {
                        method: 'POST',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            countAnnouncements: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/count-announcements', {
            }, {
                query: {
                     method: 'GET',
                     isArray: false
                }
            }, {
                cancellable: true
            }),
            
            updateNews: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/update-announcement', {
            }, {
                    update: {
                        method: 'POST',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            setNewsOnTop: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/set-announcement-on-top', {
            }, {
                    update: {
                        method: 'POST',
                        isArray: false
                    }
                }, {
                    cancellable: true
                })

        };
        return serv;
    }]);
}